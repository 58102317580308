import { CustomerInvoicingMode } from '@/login/loginModel';
import store from '@/store';

export function getAppName() {
    return process.env.VUE_APP_NAME as string;
}

export function getPagePhotosUrl() {
    return process.env.VUE_APP_PAGE_PHOTOS_BASE_URL;
}

export function getBaseImagesUrl() {
    return process.env.VUE_APP_IMAGES_BASE_URL;
}

export function getCategoriesImagesUrl() {
    return process.env.VUE_APP_CATEGORIES_IMAGES_BASE_URL;
}

export function getBaseInvoicesUrl() {
    return process.env.VUE_APP_INVOICES_BASE_URL;
}

export function getConversationAttachmentsUrl() {
    return process.env.VUE_APP_CONVERSATION_ATTACHMENTS_BASE_URL;
}

export function getBaseServiceUrl() {
    return process.env.VUE_APP_API_URL;
}

export function getBaseUrl() {
    return process.env.VUE_APP_BASE_URL;
}

export function getJitsiDomain() {
    return process.env.VUE_APP_JITSI_DOMAIN;
}


export function getCommisionPercentage() {
    return parseInt(process.env.VUE_APP_COMMISSION_PERCENTAGE!, 10);
}

export function getVatPercentage() {
    return store.getters.module.user!.customerInvoicingMode === CustomerInvoicingMode.FullVat ?
    parseInt(process.env.VUE_APP_VAT_PERCENTAGE!, 10) : 0;
}

export function getFacebookClientId() {
    return process.env.VUE_APP_FACEBOOK_CLIENT_ID;
}

export function getAnalyticsId() {
    return process.env.VUE_APP_ANALYTICS_ID;
}

export function getPaypalClientId() {
    return process.env.VUE_APP_PAYPAL_CLIENT_ID;
}

export function getGoogleApiKey() {
    return process.env.VUE_APP_GOOGLE_API_KEY;
}