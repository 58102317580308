

import { Component, Prop, Vue } from "vue-property-decorator";
import store from "../store/index";

@Component
export default class TopBarSearch extends Vue {
  
  searchText = "";
  onSearchSubmit() {
    if (!this.searchText) {
      return;
    }
    this.$router.push(`/search/${this.searchText}`).catch((error) => {});
  }
}
