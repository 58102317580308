
import { Component, Prop, Vue } from "vue-property-decorator";
import { AxiosResponse } from "axios";
import store from "../store/index";
import { balance } from "../serviceClients/userWebServices";

@Component
export default class Balance extends Vue {
  get isLoading() {
    return store.getters.module.isBalanceLoading;
  }

  get balance() {
    return store.getters.module.balance;
  }
  get balanceWatcher() {
    return this.balance;
  }

  async mounted() {
    await store.dispatch.module.loadBalance();
  }
}
