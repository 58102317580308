
import { Component, Vue } from "vue-property-decorator";
import store from "../store/index";
import { LoggedUserInformation } from "../login/loginModel";
import { fetchNumberOfUnreadConversations } from "@/messaging/messagingWebServices";

@Component({
  components: {
  },
})
export default class LoggedUser extends Vue {
  mounted() {
    this.updateNumberOfUnreadMessages()
  }

  updated() {
    this.updateNumberOfUnreadMessages()
  }

  get loggedUser() {
    return store.getters.module.user as LoggedUserInformation;
  }

  private updateNumberOfUnreadMessages() {
    if (this.loggedUser) {
      fetchNumberOfUnreadConversations().then((response) => {
        return store.commit.messaging.setNumberOfUnreadConversations(response.data);
      }).catch(() => { /* no bigie, better not to show error */ });
    }
  }

  get numberOfUnreadMessages() {
    return store.state.messaging.unreadConversations;
  }

  async logout() {
    store.commit.module.setUser(undefined);
    await store.dispatch.module.saveUserToStorage();
    await this.$router.push("/");
    window.location.reload();
  }
}
