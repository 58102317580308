import { getAppName } from "@/helpers/settingsHelper";
import store from "@/store";
import { vue } from "@/main"
import VueI18n from "vue-i18n";

const appName = getAppName();

export const refreshTitle = () => {
    document.title = appName;

    const unreadConversationsPrefix = store.state.messaging.unreadConversations > 0
        ? `(${store.state.messaging.unreadConversations}) `
        : '';

    const moduleName = store.getters.module.activeModuleName;

    if (!!moduleName) {
        document.title = `${unreadConversationsPrefix}${moduleName} - ${appName}`;
    } else {
        document.title = `${unreadConversationsPrefix}${appName}`;
    }
}

export const pushError = (
    message: string | VueI18n.TranslateResult,
    options?: {
        title?: string | VueI18n.TranslateResult,
        noAutoHide?: boolean,
        autoHideDelay?: number,
    }) => {
    const messageToShow = message || vue.$t('Server unavailable. Try again later.').toString();
    const noAutoHide = options?.noAutoHide ?? !options?.autoHideDelay;
    const autoHideDelay = noAutoHide ? undefined : options?.autoHideDelay || 5000;

    vue.$bvToast.toast(messageToShow.toString(), {
        title: options?.title?.toString() || 'Error',
        noAutoHide,
        autoHideDelay,
        variant: 'danger',
        toaster: 'b-toaster-bottom-center',
    })
}