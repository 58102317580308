import { AxiosResponse } from 'axios';

export default function callOnInterval<T>(func: () => Promise<AxiosResponse<T>>, intervalMs: number, callback: (response: T) => void) {
    let isRunning = false;
    const handle = setInterval(() => {
        if(isRunning) {
            return;
        }
        isRunning = true;
        func()
        .then((response) => {
            callback(response.data);
        })
        .finally(() => {
            isRunning = false;
        })
        .catch(() => {
            // do nothing
        });
    }, intervalMs);

    return () => {
        clearInterval(handle);
    }
}