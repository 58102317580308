export function saveToStorage(key: string, data: any) {
    if (key === 'user') {
        localStorage.setItem(key, JSON.stringify(data));
    } else {
        sessionStorage.setItem(key, JSON.stringify(data));
    }
}

export function getFromStorage(key: string) {
    const data = key === 'user'
        ? localStorage.getItem(key)
        : sessionStorage.getItem(key);
    if (data && data !== 'undefined') {
        return JSON.parse(data);
    }
    return undefined;
}