
import {
  getNumberOfUnseen,
  JobOfferInterestStatus,
} from "@/offers/offerWebServices";
import store from "@/store";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class JobOffersRouterLink extends Vue {
  mounted() {
    getNumberOfUnseen().then((response) => {
      store.dispatch.jobOffers.setUnseenInterest({
        status: JobOfferInterestStatus.Accepted,
        number: response.data.accepted,
      }).catch(() => { /* noop */ });
      store.dispatch.jobOffers.setUnseenInterest({
        status: JobOfferInterestStatus.Declined,
        number: response.data.declined,
      }).catch(() => { /* noop */ });
    }).catch(() => { /* better not to show errors here */ });
  }

  get numberOfTotalUnseenJobOfferInterests() {
    return store.getters.jobOffers.totalUnseen;
  }
}
