
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  getLanguage,
  setUserLanguage,
} from "@/core/translate";
import { LanguageType } from "@/core/translate/translateTypes";
import router from "@/router";
import { pushError } from "..";

@Component
export default class SelectLanguage extends Vue {

  setLanguage = (locale: LanguageType) => {
    if (getLanguage() !== locale) {
      setUserLanguage(locale).then(() => {
        router.go(0);
      })
      .catch((error) => {
        pushError(error.response?.data?.error || error);
      });
    }
  };

  get language() {
    return getLanguage();
  }

  get languageText() {
    if ( getLanguage() === 'pl') {
      return 'Polski'
    }
    if ( getLanguage() === 'ru') {
      return 'русский'
    }
    if ( getLanguage() === 'uk') {
      return 'українська'
    }
    if ( getLanguage() === 'ja') {
      return '日本語'
    }
    if ( getLanguage() === 'ro') {
      return 'Română'
    }

    return 'English'
  }
}
