import Vue from 'vue'
Vue.use(Toasted);
Vue.use(VueFilterDateFormat);
Vue.config.productionTip = false
import App from './App.vue'
import router from './router'
import store from '@/store/index'
import 'remixicon/fonts/remixicon.css';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import Toasted from 'vue-toasted';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './styles/style.scss'
import { getFromStorage } from './helpers/storageHelper';
import callOnInterval from './helpers/callOnInterval';
import VueCookies from 'vue-cookies'
import VueI18n from 'vue-i18n';
import geti18n from './core/translate';
import { startSignalR } from './notifications';
import { ping } from './serviceClients/userStatusWebServices';
import LoadScript from 'vue-plugin-load-script';
import VueGtag from "vue-gtag";
import { getAnalyticsId } from './helpers/settingsHelper';
import VueMobileDetection from 'vue-mobile-detection'
import { configureAxios } from './configure-axios';

Vue.use(LoadScript);
const user = getFromStorage('user');
if (user) {
  store.commit.module.setUser(user);
}

Vue.use(VueGtag, {
  config: { id: getAnalyticsId() }
}, router);

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueCookies)
Vue.use(VueI18n)
Vue.use(VueMobileDetection)

export const vue = new Vue({
  store: store.original,
  router,
  render: h => h(App),
  i18n: geti18n(),
  watch: {
    $route: {
      immediate: true,
        handler(to, from) {
            document.title = to.name ?? to.name == "Home" ? "Experts in many fields | Find your dream jobs & work at home" : to.name;
        }
    }
  }
});

configureAxios(vue);

vue.$mount('#app');

if (user) {
  if (store.getters.module.user) {
    startSignalR(vue);
  }
}

vue.$watch(() => store.getters.module.user, () => {
  if (store.getters.module.user) {
    startSignalR(vue);
  }
});

callOnInterval(() => {
  if (!store.getters.module.user) {
    return Promise.resolve({ data: undefined, status: 200, statusText: '', config: {}, headers: undefined });
  }
  return ping();
}, 10000, () => { /* hacer nada */ })
