import { AccountType } from '@/register/registerModel';
import { LanguageType } from '@/core/translate/translateTypes';
import { EducationLevel, Gender, MaritalStatus, UserLanguage } from '@/accountSetup/accountSetupService';
import { Address } from '@/panel/userPanelWebServices';

export default interface LoginModel {
    password: string;
    email: string;
}

export enum CustomerInvoicingMode
{
    Unknown,
    FullVat,
    ZeroVat
}

export interface LoggedUserInformation {
    email: string;
    token: string;
    firstName: string;
    lastName: string;
    gender: Gender;
    maritalStatus?: MaritalStatus;
    height?: number,
    accountType: AccountType;
    id: string;
    language: LanguageType;
    hourlyRate?: number;
    skillIds: string[];
    description: string;
    customerInvoicingMode: CustomerInvoicingMode;
    birthDate?: string;
    educationLevel?: EducationLevel;
    educationDetails?: string;
    languages: UserLanguage[];
    avatarId?: string;
    address?: Address,
    registrationDateTime: string;
}