import axios from "axios";
import { getLanguage } from "./core/translate";
import store from "./store";
import Vue from 'vue'

export function configureAxios(vue: Vue) {
    axios.defaults.params = {};

    axios.interceptors.request.use(
        config => {
            if (store.getters.module.user?.token) {
                config.headers.authorization = `Bearer ${store.getters.module.user!.token}`;
            }
            if (!config.params) {
                config.params = {};
            }
            config.params.language = getLanguage();
            return config;
        },
        error => Promise.reject(error)
    );

    axios.interceptors.response.use(r => r, async error => {
        if (error?.response?.status && error.response.status === 401) {
            await store.dispatch.module.clearStoredUser()
            await vue.$router.push('/login');
        }

        return Promise.reject(error);
    })
}